import { FC } from 'react';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import ActionEditorBase from '../ActionEditorBase';
import ShowInResourceView from '../ShowInResourceView';

const DateResourceEditor: FC<EditorProps> = (props) => {
  return (
    <div data-cy="date-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <ShowInResourceView {...props} />
    </div>
  );
};

export default DateResourceEditor;
